













import { Component, Vue } from "vue-property-decorator";
import { AlertService, AlertModel } from "@/core/services";
import {} from "@/core/components";
import {} from "@/core/models";
import { AlertItemCpt } from "./components";
import AppVue from "@/AppVue.vue";

@Component({
  components: { AlertItemCpt },
})
export default class AlertView extends AppVue {
  model: AlertModel = new AlertModel();
  alertId = 0;
  async created() {
    this.alertId = parseInt(this.$route.params.alertId, 10);
    this.model = await AlertService.get(this.alertId);
  }

  async mounted() {}
}
