


















































































import { Component, Vue, Prop } from "vue-property-decorator";
import { AlertModel, AlertDetailModel } from "@/core/services";
import { SectorIconCpt } from "@/core/components";
import {} from "@/core/models";
import { roleFlag } from "@/core/constants";

import AppVue from "@/AppVue.vue";

@Component({
  components: {
    SectorIconCpt,
  },
})
export default class AlertItemCpt extends AppVue {
  @Prop() model: AlertModel;
  roleFlag = roleFlag;

  get projectAlerts() {
    if (!this.model || !this.model.alertDetails) {
      return {};
    }
    return this.model.alertDetails.find((x) => !x.companyId);
  }
  get companyAlerts() {
    if (!this.model || !this.model.alertDetails) {
      return [];
    }
    return this.model.alertDetails.filter((x) => !!x.companyId);
  }
  async created() {}

  async mounted() {}
}
