var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"alert-container"},[(_vm.projectAlerts)?_c('div',[_c('h3',[_vm._v(" "+_vm._s(_vm._f("number")(_vm.projectAlerts.projects.length))+" "+_vm._s(_vm.projectAlerts.projects.length > 1 ? "projects" : "project")+" alert ")]),_vm._l((_vm.projectAlerts.projects),function(project,index){return _c('div',{key:index,staticClass:"project-alert"},[(!!project)?_c('router-link',{attrs:{"to":{
          name: 'Project',
          params: {
            projectId: project.projectId,
          },
        }}},[_c('SectorIconCpt',{attrs:{"categoryName":project.categories.length > 0
              ? project.categories[0].category
              : ''}}),_c('span',{staticClass:"title"},[_vm._v(" "+_vm._s(project.title)+" ")]),_c('span',{staticClass:"stage"},[_vm._v(" "+_vm._s(project.stage)+" ")]),_c('span',{staticClass:"county"},[_c('i',{staticClass:"el-icon-location"}),_vm._v(" "+_vm._s(project.county)+" ")]),_c('span',{staticClass:"updated"},[_c('i',{staticClass:"cis-icon-refresh-white"}),_vm._v(" "+_vm._s(_vm._f("cisDate")(project.dateUpdated)))])],1):_vm._e()],1)})],2):_vm._e(),_vm._l((_vm.companyAlerts),function(companyAlert){return _c('div',{key:companyAlert.companyId},[_c('h3',[_vm._v(" "+_vm._s(_vm._f("number")(companyAlert.projects.length))+" project "+_vm._s(companyAlert.projects.length > 1 ? "alerts" : "alert")+" for "+_vm._s(companyAlert.company.name)+" ")]),_vm._l((companyAlert.projects),function(project,index){return _c('div',{key:index,staticClass:"project-alert"},[(!!project)?_c('router-link',{attrs:{"to":{
          name: 'Project',
          params: {
            projectId: project.projectId,
          },
        }}},[_c('SectorIconCpt',{attrs:{"categoryName":project.categories.length > 0
              ? project.categories[0].category
              : ''}}),_c('span',{staticClass:"title"},[_vm._v(" "+_vm._s(project.title)+" ")]),_c('span',{staticClass:"stage"},[_vm._v(" "+_vm._s(project.stage)+" ")]),_c('span',{staticClass:"county"},[_c('i',{staticClass:"el-icon-location"}),_vm._v(" "+_vm._s(project.county)+" ")]),_c('span',{staticClass:"updated"},[_c('i',{staticClass:"cis-icon-refresh-white"}),_vm._v(" "+_vm._s(_vm._f("cisDate")(project.dateUpdated)))])],1):_vm._e()],1)})],2)})],2)}
var staticRenderFns = []

export { render, staticRenderFns }