























import { Component, Vue } from "vue-property-decorator";
import { AlertService, AlertModel } from "@/core/services";
import {} from "@/core/components";
import {} from "@/core/models";
import { AlertItemCpt } from "./components";

import AppVue from "@/AppVue.vue";

@Component({
  components: { AlertItemCpt },
})
export default class AlertsView extends AppVue {
  model: AlertModel[] = [];
  async created() {
    this.model = await AlertService.getLatest(30);
  }

  async mounted() {}
}
